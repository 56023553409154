import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ApolloProvider } from 'react-apollo'
import client from './graphql/client'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyle from './theme/GlobalStyle'
import theme from './theme/theme'
import { ThemeProvider } from './theme/styled-components'

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <GlobalStyle />
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
