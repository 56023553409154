export const startSession = (args: { token: string }) => {
  setToken(args.token)
}

export const clearSession = () => {
  setToken(null)
  if (window.location.pathname !== '/login') {
    window.location.href = '/'
  }
}

export const setToken = (token: string | null) => {
  if (!token) {
    window.localStorage.removeItem('token')
  } else {
    window.localStorage.setItem('token', token)
  }
}

export const getToken = (): string => {
  return window.localStorage.getItem('token') || ''
}
