import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { clearSession } from '../helpers/auth'
import { home } from '../routes/links'

interface LogoutPageProps extends RouteComponentProps {}

class LogoutPage extends Component<LogoutPageProps> {
  constructor(props: LogoutPageProps) {
    super(props)

    clearSession()
    this.props.history.replace(home())
  }

  render() {
    return null
  }
}

export default LogoutPage
