import styled from '../theme/styled-components'

const Select = styled.select`
  border: none;
  padding: 13px 16px;
  font-size: 15px;
  border-radius: 6px;
  color: #dff0f7;
  background: #324750;

  /* Allow setting custom padding. */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ::placeholder {
    color: #849aa2;
  }
`

export default Select
