import styled from '../theme/styled-components'

const Button = styled.button`
  border: none;
  padding: 13px 16px;
  font-size: 15px;
  border-radius: 6px;
  background: #168863;
  color: #fafffd;

  &:active,
  &:hover {
    color: #ffffff;
    background: #3592d0;
    cursor: pointer;
  }

  &:disabled {
    background: #2b393e;
    color: #66777d;
    cursor: initial;
  }
`

export default Button
