import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { from, ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { getToken, clearSession } from '../helpers/auth'

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getToken()
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }

  return forward!(operation)
})

const logoutLink = onError(({ networkError }: any) => {
  const statusCode: number =
    networkError && networkError.statusCode ? networkError.statusCode : null

  if (statusCode === 401 || statusCode === 403) {
    clearSession()
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    authMiddleware,
    logoutLink,
    createUploadLink({ uri: process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql' }),
  ]),
})

export default client
