import styled from '../theme/styled-components'

const FormSection = styled.section`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0px;
  }
`

export default FormSection
