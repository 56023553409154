import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Button from '../components/Button'
import CategoryContainer from '../components/CategoryContainer'
import CategoryDropdown from '../components/CategoryDropdown'
import ErrorMessage from '../components/ErrorMessage'
import FormSection from '../components/FormSection'
import Input from '../components/Input'
import Label from '../components/Label'
import { adminCategories } from '../routes/links'
import { ErrorType } from '../types/client'
import {
  ICategory,
  ID,
  IMutation,
  IUpdateCategoryOnMutationArguments,
} from '../types/schema-types'
import Page from '../components/Page'

const AdminCategoryPage: React.SFC<
  RouteComponentProps<{ idSlug: ID }>
> = props => {
  const [categoryId] = props.match.params.idSlug.split('-')

  return (
    <Page>
      <h1>Category</h1>
      <CategoryContainer selectedCategoryId={categoryId}>
        {({ categories, activeCategory, loading }) => {
          if (loading) {
            return <div>Loading...</div>
          }
          if (!activeCategory) {
            return <div>Could not find category</div>
          }

          const filteredCategories = categories.filter(
            v => v.id !== activeCategory.id
          )

          return (
            <CategoryEditForm
              category={activeCategory}
              categories={filteredCategories}
            />
          )
        }}
      </CategoryContainer>
    </Page>
  )
}

interface CategoryEditFormProps extends RouteComponentProps {
  category: ICategory
  categories: ICategory[]
}

const CategoryEditForm = withRouter<CategoryEditFormProps>(
  ({ category, categories, history }) => {
    const [parentId, setParentId] = useState<string>(category.parent || '')
    const [categoryTitle, setCategoryTitle] = useState<string>(category.title)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<ErrorType>(null)

    return (
      <MutationUpdateCategory mutation={MUTATION_UPDATE_CATEGORY}>
        {updateCategory => (
          <form
            onSubmit={async e => {
              e.preventDefault()
              setError('')
              setLoading(true)
              try {
                await updateCategory({
                  variables: {
                    input: {
                      categoryId: category.id,
                      parentCategoryId: parentId,
                      title: categoryTitle,
                    },
                  },
                })

                history.push(adminCategories())
                return
              } catch (err) {
                setError(err)
              }
              setLoading(false)
            }}
          >
            <ErrorMessage error={error} />
            <FormSection>
              <Label>Parent category</Label>
              <CategoryDropdown
                categories={categories}
                value={parentId}
                onChange={e => setParentId(e.currentTarget.value)}
              />
            </FormSection>

            <FormSection>
              <Label>Category name</Label>
              <Input
                type="text"
                placeholder="Category name..."
                value={categoryTitle}
                onChange={e => setCategoryTitle(e.currentTarget.value)}
              />
            </FormSection>

            <FormSection>
              <Button type="submit" disabled={loading}>
                Update
              </Button>
            </FormSection>
          </form>
        )}
      </MutationUpdateCategory>
    )
  }
)

const MUTATION_UPDATE_CATEGORY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      parent
      children
      title
      slug
    }
  }
`

class MutationUpdateCategory extends Mutation<
  Pick<IMutation, 'updateCategory'>,
  IUpdateCategoryOnMutationArguments
> {}

export default AdminCategoryPage
