import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import {
  IMutation,
  IRemovePhotoOnMutationArguments,
} from '../types/schema-types'

const MUTATION_REMOVE_PHOTO = gql`
  mutation RemovePhoto($input: RemovePhotoInput!) {
    removePhoto(input: $input)
  }
`

export class MutationRemovePhoto extends Mutation<
  Pick<IMutation, 'removePhoto'>,
  IRemovePhotoOnMutationArguments
> {
  static defaultProps = {
    mutation: MUTATION_REMOVE_PHOTO,
  }
}
