import { normalize } from 'styled-normalize'
import { createGlobalStyle } from './styled-components'

const GlobalStyle = createGlobalStyle`
  ${normalize};

  html,
  body,
  #root {
    height: 100%;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 16px;
    color: ${p => p.theme.color};
    background-color: ${p => p.theme.backgroundColor};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a {
    color: lightskyblue;
    text-decoration: none;

    &:hover,
    &:active {
      color: wheat;
    }
  }

`

export default GlobalStyle
