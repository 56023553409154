import React, { ChangeEvent, ReactNode } from 'react'
import { ICategory, ID } from '../types/schema-types'
import Select from './Select'
import { sortAlpha } from '../helpers/sort'

interface CategoryDropdownProps {
  categories: ICategory[]
  value: string
  disabled?: boolean
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

const CategoryDropdown: React.SFC<CategoryDropdownProps> = ({
  categories,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Select required disabled={disabled} onChange={onChange} value={value}>
      <option value="0">─ Root category ─</option>
      {formatCategories(categories, (v, depth) => (
        <option value={v.id}>
          ├{'─'.repeat(depth)} {v.title}
        </option>
      ))}
    </Select>
  )
}

// TODO. Split better...
export const PhotoCategoryDropdown: React.SFC<CategoryDropdownProps> = ({
  categories,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Select required disabled={disabled} onChange={onChange} value={value}>
      <option value="" disabled>
        Choose category
      </option>
      {formatCategories(categories, (v, depth) => (
        <option value={v.id}>
          ├{'─'.repeat(depth)} {v.title}
        </option>
      ))}
    </Select>
  )
}

export const formatCategories = (
  categories: ICategory[],
  callback: (category: ICategory, depth: number) => ReactNode,
  current: ID | null = null,
  depth: number = 0
) => {
  const roots = categories
    .filter(v => v.parent === current)
    .sort(sortAlpha('title'))

  return roots.map(v => {
    return (
      <React.Fragment key={v.id}>
        {callback(v, depth)}
        {formatCategories(categories, callback, v.id, depth + 1)}
      </React.Fragment>
    )
  })
}

export default CategoryDropdown
