import React from 'react'
import { Route, Switch, RouteProps } from 'react-router-dom'
import { getToken } from '../helpers/auth'
import CategoryPage from '../pages/CategoryPage'
import HomePage from '../pages/HomePage'
import LinksPage from '../pages/LinksPage'
import LoginPage from '../pages/LoginPage'
import NotFound from '../pages/NotFound'
import AdminCategoriesPage from '../pages/AdminCategoriesPage'
import AdminCategoryPage from '../pages/AdminCategoryPage'
import AdminCategoryAddPage from '../pages/AdminCategoryAddPage'
import AdminPhotoEditPage from '../pages/AdminPhotoEditPage'
import AdminPhotoAddPage from '../pages/AdminPhotoAddPage'
import PhotoPage from '../pages/PhotoPage'
import LogoutPage from '../pages/LogoutPage'
import {
  home,
  category,
  login,
  links,
  photo,
  logout,
  adminCategories,
  adminCategoryAdd,
  adminCategory,
  adminPhotoAdd,
  adminPhotoEdit,
} from './links'

const Routes = () => {
  return (
    <Switch>
      <Route exact path={photo()} component={PhotoPage} />
      <Route exact path={links()} component={LinksPage} />
      <Route exact path={login()} component={LoginPage} />
      <Route exact path={logout()} component={LogoutPage} />
      <Route exact path={category()} component={CategoryPage} />
      <Route exact path={home()} component={HomePage} />

      <PrivateRoute
        exact
        path={adminCategories()}
        component={AdminCategoriesPage}
      />
      <PrivateRoute
        exact
        path={adminCategoryAdd()}
        component={AdminCategoryAddPage}
      />
      <PrivateRoute
        exact
        path={adminCategory()}
        component={AdminCategoryPage}
      />

      <PrivateRoute
        exact
        path={adminPhotoAdd()}
        component={AdminPhotoAddPage}
      />
      <PrivateRoute
        exact
        path={adminPhotoEdit()}
        component={AdminPhotoEditPage}
      />

      <Route component={NotFound} />
    </Switch>
  )
}

const PrivateRoute: React.SFC<RouteProps> = props => {
  if (!getToken()) {
    return <NotFound />
  }

  return <Route {...props} />
}

export default Routes
