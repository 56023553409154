import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import Button from '../components/Button'
import ErrorMessage from '../components/ErrorMessage'
import ExifDataList from '../components/ExifDataList'
import { MutationRemovePhoto } from '../components/MutationRemovePhoto'
import Page from '../components/Page'
import PhotoViewer from '../components/PhotoViewer'
import { QueryPhoto, QUERY_PHOTO } from '../components/QueryPhoto'
import Section from '../components/Section'
import { ViewerContext, withViewer } from '../components/ViewerContainer'
import { getParam } from '../helpers/url'
import { adminPhotoEdit, home } from '../routes/links'
import styled from '../theme/styled-components'
import { IPhotoOnQueryArguments } from '../types/schema-types'

interface PhotoGridProps
  extends RouteComponentProps<{ idSlug: string }>,
    ViewerContext {}

const PhotoGrid: React.SFC<PhotoGridProps> = ({
  authenticated,
  history,
  match: {
    params: { idSlug },
  },
}) => {
  const [photoId] = idSlug.split('-')

  // eslint-disable-next-line eqeqeq
  const recent = getParam('recent') == 'true' ? true : undefined

  const variables: IPhotoOnQueryArguments = {
    photoId,
    recent,
  }

  return (
    <Page>
      <QueryPhoto
        query={QUERY_PHOTO}
        variables={variables}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) return <div>Loading...</div>
          if (error) return <ErrorMessage error={error} />
          if (!data || !data.photo) return <div>No data</div>

          const { photo: p } = data

          return (
            <Section>
              {authenticated && (
                <Actions wide>
                  <Action>
                    <Link to={adminPhotoEdit(idSlug)}>
                      <Button>Edit photo</Button>
                    </Link>
                  </Action>

                  <MutationRemovePhoto variables={{ input: { photoId: p.id } }}>
                    {mutate => {
                      return (
                        <Button
                          onClick={async e => {
                            e.preventDefault()

                            if (!window.confirm('Are you sure you wish to delete this photo?')) {
                              return
                            }

                            const deleted = await mutate()
                            if (deleted) {
                              history.replace(home())
                            }
                          }}
                        >
                          Delete photo
                        </Button>
                      )
                    }}
                  </MutationRemovePhoto>
                </Actions>
              )}

              <Section wide>
                <PhotoViewer
                  title={p.title}
                  url={p.url}
                  nextPhotoId={p.nextPhotoId}
                  previousPhotoId={p.previousPhotoId}
                  recent={recent}
                />
              </Section>
              <PhotoInfo>
                <PhotoTitle>{p.title}</PhotoTitle>
                <Flex>
                  <Description>{p.description}</Description>
                  {p.exif && (
                    <Sidebar>
                      <ExifDataList exif={p.exif} />
                    </Sidebar>
                  )}
                </Flex>
              </PhotoInfo>
            </Section>
          )
        }}
      </QueryPhoto>
    </Page>
  )
}

const Action = styled.span`
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
`

const Actions = styled(Section)`
  margin-bottom: ${p => p.theme.gutter};
  background: #30363c;
  padding: 12px 48px;
`

const PhotoTitle = styled.h1`
  margin-top: 0;
`

const PhotoInfo = styled.div`
  padding: ${p => p.theme.gutter};
  display: flex;
  max-width: 1060px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
`

const Description = styled.div`
  flex: 1;
`

const Sidebar = styled.div`
  flex: 0;
  min-width: 40%;
`

export default withViewer(PhotoGrid)
