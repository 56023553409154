import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getGoogleUrl } from '../helpers/oauth-google'

interface LoginButtonProps extends RouteComponentProps {}

const LoginButton: React.SFC<LoginButtonProps> = ({
  location: { pathname },
  children,
}) => {
  return (
    <a href={getGoogleUrl(pathname)} rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default withRouter(LoginButton)
