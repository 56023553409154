import React from 'react'
import styled from '../theme/styled-components'
import { ErrorType } from '../types/client'

interface ErrorMessageProps {
  error: ErrorType
}

const ErrorMessage: React.SFC<ErrorMessageProps> = props => {
  const message = getError(props.error)
  if (!message) {
    return null
  }
  return <Container>{message || props.children}</Container>
}

const Container = styled.div`
  color: #ffffff;
  background: #e74c3c;
  padding: 16px;
  border-radius: 6px;
`

const getError = (err: any): string | null => {
  if (!err) {
    return null
  }
  if (err.graphQLErrors && err.graphQLErrors.length) {
    return err.graphQLErrors[0].message || null
  }
  if (
    err.networkError &&
    err.networkError.result &&
    err.networkError.result.errors &&
    err.networkError.result.errors
  ) {
    return err.networkError.result.errors[0].message || null
  }

  return err.message || err || null
}

export default ErrorMessage
