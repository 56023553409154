import React, { Component } from 'react'
import Layout from './components/Layout'
import Routes from './routes/Routes'
import { ViewerProvider } from './components/ViewerContainer'

class App extends Component {
  render() {
    return (
      <ViewerProvider>
        <Layout>
          <Routes />
        </Layout>
      </ViewerProvider>
    )
  }
}

export default App
