import styled from '../theme/styled-components'

const Textarea = styled.textarea`
  border: none;
  padding: 13px 16px;
  font-size: 15px;
  border-radius: 6px;
  color: #dff0f7;
  background: #324750;

  ::placeholder {
    color: #849aa2;
  }
`

export default Textarea
