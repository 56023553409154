import React from 'react'
import PhotoAddForm from '../components/PhotoAddForm'
import CategoryContainer from '../components/CategoryContainer'
import Page from '../components/Page'

const AdminPhotoAddPage: React.SFC = props => {
  return (
    <Page>
      <CategoryContainer>
        {({ categories }) => <PhotoAddForm categories={categories} />}
      </CategoryContainer>
    </Page>
  )
}

export default AdminPhotoAddPage
