export const getGoogleClientId = () => {
  return '694204645170-rakttbckv4c31pra7e4il4nos68apcvu.apps.googleusercontent.com'
}

export const getGoogleUrl = (pathname = window.location.pathname) => {
  const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
  const params = [
    'scope=openid%20email',
    `state=${pathname}`,
    'response_type=id_token',
    'nonce=1234',
    `client_id=${getGoogleClientId()}`,
  ]
  // const siteUrl = window.location.href.replace(/[^/]+$/, '')
  const siteUrl = `${window.location.protocol}//${window.location.host}/`
  params.push(`redirect_uri=${siteUrl}login`)

  return `${baseUrl}?${params.join('&')}`
}
