const theme = {
  gutter: '24px',
  gutterSm: '12px',

  color: '#fff',
  // colorSecondary: '#7a8084',
  colorSecondary: '#636a70',

  // backgroundColor: '#222',
  backgroundColor: '#202529',

  // borderColor: '#252b2f'
  borderColor: '#32383c',
}

export type ThemeInterface = typeof theme

export default theme
