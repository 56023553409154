export const getHash = (key: string): string => {
  const query = window.location.hash
  if (query) {
    const val = query.match(new RegExp(`(?:\\#|&)${key}=(.*?)(?:&|$)`))
    if (val) {
      return decodeURIComponent(val[1])
    }
  }

  return ''
}

export const getParam = (key: string): string => {
  const query = window.location.search
  if (query) {
    const val = query.match(new RegExp(`(?:\\?|&)${key}=(.*?)(?:&|$)`))
    if (val) {
      return decodeURIComponent(val[1])
    }
  }

  return ''
}
