import React from 'react'
import styled from '../theme/styled-components'
import LoginButton from './LoginButton'
import { Link } from 'react-router-dom'
import { adminPhotoAdd, logout, adminCategories } from '../routes/links'
import { withViewer, ViewerContext } from './ViewerContainer'

const Footer: React.SFC<ViewerContext> = ({ authenticated }) => {
  const year = new Date().getFullYear()
  return (
    <Container>
      <span>&copy; {year} Per Svensson</span>
      {authenticated ? (
        <span>
          <Link to={adminPhotoAdd()}>Upload</Link>
          <Link to={adminCategories()}>Categories</Link>
          <Link to={logout()}>Logout</Link>
        </span>
      ) : (
        <span>
          <LoginButton>Login</LoginButton>
        </span>
      )}
    </Container>
  )
}

const Container = styled.footer`
  flex: none;
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  box-shadow: inset 0 1px 0 0px ${p => p.theme.borderColor};
  /* color: #737373; */
  color: ${p => p.theme.colorSecondary};
  font-size: 14px;

  a {
    color: ${p => p.theme.colorSecondary};
    text-decoration: none;
    margin-left: 16px;

    &:hover,
    &:active {
      color: #eee;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

export default withViewer(Footer)
