import gql from 'graphql-tag'
import React, { useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { RouteComponentProps, withRouter } from 'react-router'
import { photo } from '../routes/links'
import { ErrorType } from '../types/client'
import {
  IAddPhotoOnMutationArguments,
  ICategory,
  IMutation,
} from '../types/schema-types'
import Button from './Button'
import { PhotoCategoryDropdown } from './CategoryDropdown'
import ErrorMessage from './ErrorMessage'
import FileInput from './FileInput'
import FormSection from './FormSection'
import Input from './Input'
import Label from './Label'
import Textarea from './Textarea'

interface PhotoAddFormProps extends RouteComponentProps {
  categories: ICategory[]
}

const PhotoAddForm: React.SFC<PhotoAddFormProps> = ({
  history,
  categories,
}) => {
  const [title, setTitle] = useState<string>('')
  const [categoryId, setCategoryId] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [currentFile, setCurrentFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorType>(null)
  const fileInputEl = useRef<HTMLInputElement>(null)

  return (
    <MutationAddPhoto mutation={MUTATION_ADD_PHOTO}>
      {mutate => (
        <form
          onSubmit={async e => {
            e.preventDefault()
            if (currentFile == null) {
              return
            }
            try {
              setError(null)
              setLoading(true)
              const res = await mutate({
                variables: {
                  input: {
                    file: currentFile,
                    title,
                    description,
                    categoryId,
                  },
                },
              })
              if (res && res.data && res.data.addPhoto) {
                history.push(photo(res.data.addPhoto.id))
                return
              }
              const { current } = fileInputEl
              if (current) {
                current.value = ''
                setCurrentFile(null)
              }
            } catch (err) {
              setError(err)
            }
            setLoading(false)
          }}
        >
          <ErrorMessage error={error} />

          <FormSection>
            <Label>Photo title</Label>
            <Input
              disabled={loading}
              required
              type="text"
              placeholder="Title..."
              value={title}
              onChange={e => setTitle(e.currentTarget.value)}
            />
          </FormSection>

          <FormSection>
            <Label>Category</Label>
            <PhotoCategoryDropdown
              disabled={loading}
              categories={categories}
              onChange={e => setCategoryId(e.currentTarget.value)}
              value={categoryId}
            />
          </FormSection>

          <FormSection>
            <Label>Description</Label>
            <Textarea
              disabled={loading}
              required
              placeholder="Description..."
              cols={80}
              rows={12}
              value={description}
              onChange={e => setDescription(e.currentTarget.value)}
            />
          </FormSection>

          <FormSection>
            <FileInput
              disabled={loading}
              required
              accept="image/jpeg,image/png"
              ref={fileInputEl as any}
              onChange={({ target: { validity, files } }) => {
                if (validity.valid && files && files[0]) {
                  setCurrentFile(files[0])
                }
              }}
            />
          </FormSection>

          <FormSection>
            <Button type="submit" disabled={loading || !currentFile}>
              {loading ? 'Uploading...' : 'Upload photo'}
            </Button>
          </FormSection>
        </form>
      )}
    </MutationAddPhoto>
  )
}

const MUTATION_ADD_PHOTO = gql`
  mutation AddPhoto($input: AddPhotoInput!) {
    addPhoto(input: $input) {
      id
    }
  }
`

class MutationAddPhoto extends Mutation<
  Pick<IMutation, 'addPhoto'>,
  IAddPhotoOnMutationArguments
> {}

export default withRouter(PhotoAddForm)
