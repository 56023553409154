import React from 'react'
import Page from '../components/Page'

const links = [
  { title: 'juzaphoto.com', href: 'https://www.juzaphoto.com' },
  { title: 'netfugl.dk', href: 'https://netfugl.dk' },
  { title: 'nfd.dk', href: 'https://nfd.dk' },
  { title: 'dof.dk', href: 'https://dof.dk' },
  { title: 'naturephotos.dk', href: 'http://naturephotos.dk' },
  { title: '1x.com', href: 'https://1x.com' },
  { title: 'javiermilla.es', href: 'http://javiermilla.es' },
  { title: 'birdfocus.nl', href: 'http://birdfocus.nl' },
  { title: 'klausbrodersen.dk', href: 'http://klausbrodersen.dk' },
  { title: 'naturlinsen.dk', href: 'http://naturlinsen.dk' },
]

const LinksPage = () => {
  return (
    <Page>
      <ul>
        {links.map(({ href, title }) => (
          <li key={href}>
            <a href={href} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </li>
        ))}
      </ul>
    </Page>
  )
}

export default LinksPage
