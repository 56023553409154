import React from 'react'
import Header from './Header'
import Footer from './Footer'
import styled from '../theme/styled-components'

const Layout: React.SFC = props => {
  return (
    <Container>
      <Header />
      {props.children}
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export default Layout
