import React from 'react'
import styled from '../theme/styled-components'
import { NavLink } from 'react-router-dom'
import CategoryContainer from './CategoryContainer'
import { home, links, category, slug } from '../routes/links'

const Navigation = () => {
  return (
    <Container>
      <div>
        <Link exact to={home()}>
          Home
        </Link>
        <CategoryContainer>
          {({ categories }) => {
            return categories
              .filter(v => v.parent === null && v.title.trim())
              .map(v => (
                <Link key={v.id} exact to={category(slug(v))}>
                  {v.title}
                </Link>
              ))
          }}
        </CategoryContainer>
      </div>
      <div>
        <Link exact to={links()}>
          Links
        </Link>
      </div>
    </Container>
  )
}

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Link = styled(NavLink)`
  margin-left: 16px;
  text-decoration: none;

  color: white;

  &.active {
    color: #05bf05;
  }

  &:first-child {
    margin-left: 0;
  }
`

export default Navigation
