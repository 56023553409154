import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { IQuery, IPhotoOnQueryArguments } from '../types/schema-types'

export const QUERY_PHOTO = gql`
  query Photo($photoId: ID!, $recent: Boolean) {
    photo(photoId: $photoId, recent: $recent) {
      id
      categoryId
      url
      filename
      title
      slug
      description
      nextPhotoId
      previousPhotoId
      exif {
        model
        focalLength
        fNumber
        exposureTime
        iso
        flash
        lens
        date
      }
      thumbnail {
        url
        width
        height
      }
    }
  }
`

export class QueryPhoto extends Query<
  Pick<IQuery, 'photo'>,
  IPhotoOnQueryArguments
> {}
