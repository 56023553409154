import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { getToken, startSession } from '../helpers/auth'
import { getHash } from '../helpers/url'
import { home } from '../routes/links'
import { withViewer, ViewerContext } from '../components/ViewerContainer'

interface LoginPageProps extends RouteComponentProps, ViewerContext {}

class LoginPage extends Component<LoginPageProps> {
  constructor(props: LoginPageProps) {
    super(props)

    const {
      history: { replace },
    } = props
    const token = getHash('id_token')
    const state = getHash('state')

    if (token) {
      startSession({ token })
      replace(state.startsWith('/') ? state : home())
      return
    }

    if (getToken()) {
      // Should probably check if the token is valid.
      replace(home())
      return
    }

    replace(home())
  }

  render() {
    return null
  }
}

export default withViewer(LoginPage)
