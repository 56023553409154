import React, { createContext } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { IQuery, IUser } from '../types/schema-types'
import { Omit } from '../types/client';

export interface ViewerContext {
  authenticated: boolean
  viewer: IUser | null
}

export const ViewerContext = createContext<ViewerContext>({
  authenticated: false,
  viewer: null,
})

export const Viewer = ViewerContext.Consumer

export const ViewerProvider: React.SFC = ({ children }) => {
  return (
    <QueryViewer query={QUERY_VIEWER}>
      {({ data, loading }) => {
        // if ((!data || !data.viewer) && loading) {
        //   return <div>Loading...</div>
        // }

        const value =
          data && data.viewer
            ? { authenticated: true, viewer: data.viewer }
            : { authenticated: false, viewer: null }

        return (
          <ViewerContext.Provider value={value}>
            {children}
          </ViewerContext.Provider>
        )
      }}
    </QueryViewer>
  )
}

export function withViewer<
  InnerProps extends ViewerContext,
  OuterProps = Omit<InnerProps, keyof ViewerContext>
>(Component: React.ComponentType<InnerProps>): React.ComponentType<OuterProps> {
  return function WrapperComponent(props: OuterProps) {
    return <Viewer>{state => <Component {...props as any} {...state} />}</Viewer>
  }
}

const QUERY_VIEWER = gql`
  query Viewer {
    viewer {
      id
    }
  }
`

class QueryViewer extends Query<Pick<IQuery, 'viewer'>, {}> {}
