import styled, { css } from '../theme/styled-components'

const Section = styled.section<{
  wide?: boolean
  tall?: boolean
  gutter?: boolean
}>`
  width: auto;

  ${p =>
    p.gutter &&
    css`
      padding: ${p => p.theme.gutter};
    `}

  ${p =>
    p.wide &&
    css`
      margin-left: -${p => p.theme.gutter};
      margin-right: -${p => p.theme.gutter};
    `}

  ${p =>
    p.tall &&
    css`
      margin-top: -${p => p.theme.gutter};
      margin-bottom: -${p => p.theme.gutter};
    `}

  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
  }
`

export default Section
