import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import CategoryContainer, { childrenOf } from '../components/CategoryContainer'
import ErrorMessage from '../components/ErrorMessage'
import NotFound from './NotFound'
import PhotoGrid from '../components/PhotoGrid'
import { ID, ICategory } from '../types/schema-types'
import { category, slug } from '../routes/links'
import styled from '../theme/styled-components'
import Section from '../components/Section'
import Page from '../components/Page'

interface CategoryPageProps extends RouteComponentProps<{ idSlug: ID }> {}

const CategoryPage: React.SFC<CategoryPageProps> = props => {
  const [categoryId] = props.match.params.idSlug.split('-')

  return (
    <PageWithSidebar noGutter>
      <CategoryContainer selectedCategoryId={categoryId}>
        {({ activeCategory, categories, loading, error }) => {
          if (loading) return <Section gutter>Loading...</Section>
          if (error) return <ErrorMessage error={error} />
          if (!categories.length) return <ErrorMessage error="No categories." />

          const subCategories = childrenOf(categoryId, categories)

          if (!activeCategory) {
            return <NotFound />
          }

          return (
            <Container>
              <CategorySidebar
                parentId={activeCategory.parent}
                categories={subCategories}
              />

              <Photos>
                <Section>
                  <PhotoGrid categoryId={categoryId} />
                </Section>
              </Photos>
            </Container>
          )
        }}
      </CategoryContainer>
    </PageWithSidebar>
  )
}

const CategorySidebar: React.SFC<{
  parentId?: ID | null
  categories: ICategory[]
}> = ({ parentId, categories }) => {
  if (!parentId && !categories.length) {
    return null
  }

  return (
    <Sidebar>
      {parentId && (
        <CategoryList>
          <li>
            <CategoryItem to={category(parentId)}>&larr; Go back</CategoryItem>
          </li>
        </CategoryList>
      )}

      {categories.length > 0 && (
        <CategoryList>
          {categories.map(v => (
            <li>
              <CategoryItem to={category(slug(v))}>
                {v.title}
                {/* {v.thumbnail && <img src={v.thumbnail.url} />} */}
              </CategoryItem>
            </li>
          ))}
        </CategoryList>
      )}
    </Sidebar>
  )
}

const PageWithSidebar = styled(Page)`
  display: flex;
  flex-direction: row;
`

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Sidebar = styled.div`
  flex: 0 0 auto;
  width: auto;
  min-width: 200px;
  height: 100%;
  border-right: 1px solid ${p => p.theme.borderColor};

  /* TODO. Make responsive */
`

const Photos = styled.div`
  flex: 1;
  padding: ${p => p.theme.gutter};
`

const CategoryList = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${p => p.theme.gutter};

  & + & {
    border-top: 1px solid ${p => p.theme.borderColor};
  }
`

const CategoryItem = styled(Link)`
  display: block;
  color: #d0d5d8;
  line-height: 1.65rem;
`

export default CategoryPage
