import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { IQuery, ICategory, ID } from '../types/schema-types'
import React, { ReactNode } from 'react'
import { ApolloError } from 'apollo-client'

interface CategoryContianerProps {
  selectedCategoryId?: string
  children: (
    props: {
      loading: boolean
      error?: ApolloError
      activeCategory?: ICategory
      categories: ICategory[]
      refetch: () => void
    }
  ) => ReactNode
}

const CategoryContainer: React.SFC<CategoryContianerProps> = props => {
  return (
    <QueryCategories query={QUERY_CATEGORIES}>
      {({ data, loading, error, refetch }) => {
        const categories = (data && data.categories) || []

        const activeCategory = categories.find(
          category => category.id === props.selectedCategoryId
        )

        return props.children({
          loading,
          error,
          activeCategory,
          categories,
          refetch,
        })
      }}
    </QueryCategories>
  )
}

export const childrenOf = (
  categoryId: ID | null,
  categories: ICategory[]
): ICategory[] => {
  if (!categories || !categories.length) {
    return []
  }

  const category = categories.find(v => v.id === categoryId)
  if (!category || !category.children || !category.children.length) {
    return []
  }

  return categories.filter(v => category.children!.some(id => id === v.id))
}

const QUERY_CATEGORIES = gql`
  query Categories {
    categories {
      id
      parent
      children
      title
      slug
      thumbnail {
        url
        width
        height
      }
    }
  }
`

class QueryCategories extends Query<Pick<IQuery, 'categories'>> {}

export default CategoryContainer
