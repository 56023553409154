import React from 'react'
import { Link } from 'react-router-dom'
import styled from '../theme/styled-components'
import Navigation from './Navigation'
import { home } from '../routes/links'

const Header = () => {
  return (
    <Container>
      <TextLogo to={home()}>
        <LogoPrefix>PBS</LogoPrefix>
        <LogoSuffix>Photo</LogoSuffix>
      </TextLogo>
      <Navigation />
    </Container>
  )
}

const Container = styled.header`
  flex: none;
  display: flex;
  align-items: center;
  padding: 18px 24px;
  box-shadow: inset 0 -1px 0 0px ${p => p.theme.borderColor};
`

const LogoPrefix = styled.span`
  color: green;
`

const LogoSuffix = styled.span`
  color: white;
`

const TextLogo = styled(Link)`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  margin-right: 24px;
  text-decoration: none;

  &:hover,
  &:active {
    ${LogoPrefix},
    ${LogoSuffix} {
      color: white;
    }
  }
`

export default Header
