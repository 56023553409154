import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { RouteComponentProps, withRouter } from 'react-router'
import { ErrorType } from '../types/client'
import {
  ICategory,
  IMutation,
  IPhoto,
  IUpdatePhotoOnMutationArguments,
} from '../types/schema-types'
import Button from './Button'
import { PhotoCategoryDropdown } from './CategoryDropdown'
import ErrorMessage from './ErrorMessage'
import FormSection from './FormSection'
import Input from './Input'
import Label from './Label'
import Textarea from './Textarea'
import * as URL from '../routes/links'

interface PhotoUpdateFormProps extends RouteComponentProps<{ idSlug: string }> {
  categories: ICategory[]
  photo: IPhoto
}

const PhotoUpdateForm: React.SFC<PhotoUpdateFormProps> = ({
  categories,
  history,
  photo,
}) => {
  const [title, setTitle] = useState<string>(photo.title)
  const [categoryId, setCategoryId] = useState<string | null>(photo.categoryId)
  const [description, setDescription] = useState<string>(photo.description)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorType>(null)

  return (
    <MutationUpdatePhoto mutation={MUTATION_UPDATE_PHOTO}>
      {mutate => (
        <form
          onSubmit={async e => {
            e.preventDefault()
            try {
              setError(null)
              setLoading(true)

              const res = await mutate({
                variables: {
                  input: {
                    photoId: photo.id,
                    title,
                    description,
                    categoryId,
                  },
                },
              })
              if (res && res.data && res.data.updatePhoto) {
                history.push(URL.photo(res.data.updatePhoto.id))
                return
              }
            } catch (err) {
              setError(err)
            }
            setLoading(false)
          }}
        >
          <ErrorMessage error={error} />

          <FormSection>
            <Label>Photo title</Label>
            <Input
              required
              type="text"
              placeholder="Title..."
              value={title}
              onChange={e => setTitle(e.currentTarget.value)}
            />
          </FormSection>

          <FormSection>
            <Label>Category</Label>
            <PhotoCategoryDropdown
              categories={categories}
              onChange={e => setCategoryId(e.currentTarget.value)}
              value={categoryId || ''}
            />
          </FormSection>

          <FormSection>
            <Label>Description</Label>
            <Textarea
              required
              placeholder="Description..."
              cols={80}
              rows={12}
              value={description}
              onChange={e => setDescription(e.currentTarget.value)}
            />
          </FormSection>

          <FormSection>
            <Button type="submit" disabled={loading}>
              Update photo
            </Button>
          </FormSection>
        </form>
      )}
    </MutationUpdatePhoto>
  )
}

const MUTATION_UPDATE_PHOTO = gql`
  mutation UpdatePhoto($input: UpdatePhotoInput!) {
    updatePhoto(input: $input) {
      id
      categoryId
      title
      description
    }
  }
`

class MutationUpdatePhoto extends Mutation<
  Pick<IMutation, 'updatePhoto'>,
  IUpdatePhotoOnMutationArguments
> {}

export default withRouter(PhotoUpdateForm)
