import React from 'react'
import PhotoGrid from '../components/PhotoGrid'
import Section from '../components/Section'
import Page from '../components/Page'

const HomePage: React.SFC = props => {
  return (
    <Page>
      <Section>
        <PhotoGrid />
      </Section>
    </Page>
  )
}

export default HomePage
