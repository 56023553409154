import React from 'react'
import { IEXIF } from '../types/schema-types'
import styled from '../theme/styled-components';

const ExifDataList: React.SFC<{ exif: IEXIF }> = ({
  exif: {
    model,
    lens,
    focalLength,
    fNumber,
    exposureTime,
    iso,
    date,
    flash
  }
}) => (
    <div>
      <List>
        {model && <Model>{model}</Model>}
        {lens && <Lens>{lens}</Lens>}

        {focalLength && <Item>{focalLength}</Item>}
        {fNumber && <Item>f/{fNumber}</Item>}
        {exposureTime && <Item>{exposureTime}</Item>}
        {iso && <Item>ISO {iso}</Item>}
        {flash && <Item>Flash ({flash.toLowerCase()})</Item>}

        {/* {date && <Date>{moment(date).format('LL')}</Date>} */}
      </List>
    </div>
  )

const List = styled.ul`
  font-size: .85rem;
  list-style: none;
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  font-size: .75rem;
  line-height: 1.15rem;
`

const Model = styled(Item)`
  font-size: .9rem;
  font-weight: 600;
`

const Lens = styled(Item)`
  font-size: .65rem;
  opacity: 0.6;
  margin-bottom: .35rem;
`

const Date = styled(Item)`
  opacity: 0.6;
  margin-top: .35rem;
`

export default ExifDataList
