import React from 'react'
import CategoryContainer from '../components/CategoryContainer'
import PhotoEditForm from '../components/PhotoEditForm'
import { RouteComponentProps } from 'react-router'
import { IPhotoOnQueryArguments } from '../types/schema-types'
import { QueryPhoto, QUERY_PHOTO } from '../components/QueryPhoto'
import ErrorMessage from '../components/ErrorMessage'
import Page from '../components/Page'

const AdminPhotoEditPage: React.SFC<
  RouteComponentProps<{ idSlug: string }>
> = ({
  match: {
    params: { idSlug },
  },
}) => {
  const [photoId] = idSlug.split('-')

  const variables: IPhotoOnQueryArguments = {
    photoId,
  }

  return (
    <Page>
      <QueryPhoto
        query={QUERY_PHOTO}
        variables={variables}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) return <div>Loading...</div>
          if (error) return <ErrorMessage error={error} />
          if (!data || !data.photo) return <div>No data</div>
          const { photo } = data

          return (
            <CategoryContainer>
              {({ categories }) => (
                <PhotoEditForm photo={photo} categories={categories} />
              )}
            </CategoryContainer>
          )
        }}
      </QueryPhoto>
    </Page>
  )
}

export default AdminPhotoEditPage
