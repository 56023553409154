export function sortAlpha<T extends object>(key?: keyof T) {
  if (key) {
    return (a: T, b: T) => {
      if (a[key] === b[key]) {
        return 0
      }
      return a[key] < b[key] ? -1 : 1
    }
  }
  return (a: T, b: T) => {
    if (a === b) {
      return 0
    }
    return a < b ? -1 : 1
  }
}
