import React from 'react'
import { Link } from 'react-router-dom'
import { adminCategory, adminCategoryAdd } from '../routes/links'
import styled from '../theme/styled-components'
import CategoryContainer from '../components/CategoryContainer'
import { formatCategories } from '../components/CategoryDropdown'
import Page from '../components/Page'

const AdminCategoriesPage = () => {
  return (
    <Page>
      <h1>Categories</h1>
      <div>
        <Link to={adminCategoryAdd()}>Create category</Link>
      </div>
      <List>
        <CategoryContainer>
          {({ categories }) =>
            formatCategories(categories, (v, depth) => (
              <li>
                ├{'─'.repeat(depth)} {v.title || 'Unnamed'}{' '}
                <Link to={adminCategory(v.id)}>(edit)</Link>
              </li>
            ))
          }
        </CategoryContainer>
      </List>
    </Page>
  )
}

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`

export default AdminCategoriesPage
