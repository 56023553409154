interface JustifiedGridImage {
  width: number
  height: number
  aspectRatio: number
  top: number // translateY
  left: number // translateX
}

interface JustifiedGridOptions {
  horizontalSpace: number
  verticalSpace: number
}

class JustifiedGrid {
  public horizontalSpace = 0
  public verticalSpace = 0
  public containerWidth = 0
  public windowWidth = 0

  private images: JustifiedGridImage[] = []

  constructor(
    aspectRatios: number[], // Array<{ width: number; height: number }>,
    options: JustifiedGridOptions
  ) {
    this.images = aspectRatios.map(aspectRatio => ({
      aspectRatio, // : aspectRatio.width / aspectRatio.height,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    }))
    this.horizontalSpace = options.horizontalSpace
    this.verticalSpace = options.verticalSpace
  }

  compute = (containerWidth: number) => {
    this.containerWidth = containerWidth

    let row: JustifiedGridImage[] = []

    let left = 0
    let top = 0
    let rowAspectRatio = 0

    const minAspectRatio = this.getMinAspectRatio()

    this.images.forEach((image, index) => {
      rowAspectRatio += image.aspectRatio
      row.push(image)

      if (
        rowAspectRatio >= minAspectRatio ||
        index + 1 === this.images.length
      ) {
        // Make sure the last row has a reasonable height.
        rowAspectRatio = Math.max(rowAspectRatio, minAspectRatio)

        // Compute this row's width.
        const totalDesitredWidthOfImages =
          this.containerWidth - this.horizontalSpace * (row.length - 1)
        const rowHeight = totalDesitredWidthOfImages / rowAspectRatio

        row.forEach(i => {
          const imageWidth = rowHeight * i.aspectRatio

          i.width = Math.floor(imageWidth)
          i.height = Math.floor(rowHeight)
          i.left = Math.floor(left)
          i.top = Math.floor(top)

          left += imageWidth + this.horizontalSpace
        })

        row = []
        rowAspectRatio = 0
        left = 0
        top += rowHeight + this.verticalSpace
      }
    })

    return {
      containerWidth: this.containerWidth,
      containerHeight: top - this.verticalSpace,
      images: this.images,
    }
  }

  getMinAspectRatio = () => {
    // Dynamic:
    return window.innerWidth / 300

    // Static:
    // const windowWidth = window.innerWidth
    // if (windowWidth <= 640) return 2
    // else if (windowWidth <= 1280) return 4
    // else if (windowWidth <= 1920) return 5
    // return 6
  }
}

export default JustifiedGrid
