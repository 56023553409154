import React from 'react'
import styled from '../theme/styled-components'
import Button from './Button'

const FileInput = styled.input.attrs({ type: 'file' })``

interface StyledFileInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  innerRef?: React.Ref<HTMLInputElement>
}

export const StyledFileInput: React.SFC<StyledFileInputProps> = ({
  children,
  ref,
  innerRef,
  ...rest
}) => {
  return (
    <Container>
      <Button>Upload file</Button>
      <FileInputAlternative ref={innerRef as any} type="file" {...rest} />
    </Container>
  )
}

const FileInputAlternative = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
`

export default FileInput
