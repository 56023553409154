import React from 'react'

interface GridFigureProps {
  top: number
  left: number
  width: number
  height: number
}

const GridFigure: React.SFC<GridFigureProps> = props => {
  return (
    <div
      style={{
        display: 'block',
        position: 'absolute',
        transform: `translate3d(${props.left}px, ${props.top}px, 0px)`,
        width: `${props.width}px`,
        height: `${props.height}px`,
        backgroundColor: '#3a3f44',
        fontSize: 0,
      }}
    >
      {props.children}
    </div>
  )
}

export default GridFigure
