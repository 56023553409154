import React, { ReactNode, Component, createRef } from 'react'

import JustifiedGrid from '../helpers/justified-grid'

interface GridProps {
  rowHeight: number
  gutter: number
  aspectRatios: number[]
  render: (gridImage: GridImage, index: number) => ReactNode
}

interface GridState {
  totalHeight: number
  images: GridImage[] | null
}

interface GridImage {
  width: number
  height: number
  top: number
  left: number
}

class Grid extends Component<GridProps, GridState> {
  private containerRef = createRef<HTMLDivElement>()

  private jg: JustifiedGrid | null = null

  state: GridState = {
    images: null,
    totalHeight: 0,
  }

  componentDidMount() {
    if (!this.containerRef.current) {
      return null
    }

    const jg = new JustifiedGrid(this.props.aspectRatios, {
      horizontalSpace: this.props.gutter,
      verticalSpace: this.props.gutter,
    })

    this.jg = jg

    this.update()
    window.addEventListener('resize', this.onResize)
  }

  // TODO. On props updated.

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.update()
  }

  update = () => {
    if (!this.jg || !this.containerRef.current) {
      return
    }
    const layout = this.jg.compute(this.containerRef.current.clientWidth)

    this.setState({
      totalHeight: layout.containerHeight,
      images: layout.images,
    })
  }

  render() {
    const { images, totalHeight } = this.state

    return (
      <div
        ref={this.containerRef}
        style={{ position: 'relative', height: `${totalHeight}px` }}
      >
        {images && images.map((img, i) => this.props.render(img, i))}
      </div>
    )
  }
}

export default Grid
