import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { RouteComponentProps, withRouter } from 'react-router'
import { ErrorType } from '../types/client'
import {
  IAddCategoryOnMutationArguments,
  ICategory,
  IMutation,
} from '../types/schema-types'
import Button from '../components/Button'
import CategoryContainer from '../components/CategoryContainer'
import CategoryDropdown from '../components/CategoryDropdown'
import ErrorMessage from '../components/ErrorMessage'
import FormSection from '../components/FormSection'
import Input from '../components/Input'
import Label from '../components/Label'
import { adminCategories } from '../routes/links'
import Page from '../components/Page'

const AdminCategoryPage: React.SFC<RouteComponentProps> = props => {
  return (
    <Page>
      <h1>Category</h1>
      <CategoryContainer>
        {({ categories, loading }) => {
          if (loading) {
            return <div>Loading...</div>
          }

          return <CategoryAddForm categories={categories} />
        }}
      </CategoryContainer>
    </Page>
  )
}

interface CategoryAddFormProps extends RouteComponentProps {
  categories: ICategory[]
}

const CategoryAddForm = withRouter<CategoryAddFormProps>(
  ({ categories, history }) => {
    const [parentId, setParentId] = useState<string>('')
    const [categoryTitle, setCategoryTitle] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<ErrorType>(null)

    return (
      <MutationAddCategory mutation={MUTATION_UPDATE_CATEGORY}>
        {addCategory => (
          <form
            onSubmit={async e => {
              e.preventDefault()
              setError('')
              setLoading(true)
              try {
                await addCategory({
                  variables: {
                    input: {
                      parentCategoryId: parentId,
                      title: categoryTitle,
                    },
                  },
                  refetchQueries: ['Categories'],
                  awaitRefetchQueries: true,
                })

                history.replace(adminCategories())
                return
              } catch (err) {
                setError(err)
              }
              setLoading(false)
            }}
          >
            <ErrorMessage error={error} />
            <FormSection>
              <Label>Parent category</Label>
              <CategoryDropdown
                categories={categories}
                value={parentId}
                onChange={e => setParentId(e.currentTarget.value)}
              />
            </FormSection>

            <FormSection>
              <Label>Category name</Label>
              <Input
                type="text"
                placeholder="Category name..."
                value={categoryTitle}
                onChange={e => setCategoryTitle(e.currentTarget.value)}
              />
            </FormSection>

            <FormSection>
              <Button type="submit" disabled={loading}>
                Create category
              </Button>
            </FormSection>
          </form>
        )}
      </MutationAddCategory>
    )
  }
)

const MUTATION_UPDATE_CATEGORY = gql`
  mutation AddCategory($input: AddCategoryInput!) {
    addCategory(input: $input) {
      id
      parent
      children
      title
      slug
    }
  }
`

class MutationAddCategory extends Mutation<
  Pick<IMutation, 'addCategory'>,
  IAddCategoryOnMutationArguments
> {}

export default AdminCategoryPage
