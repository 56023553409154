import React from 'react'
import styled, { css } from '../theme/styled-components'

interface PageProps {
  noGutter?: boolean
}

const Page = styled.main<PageProps>`
  flex: 1 0 auto;
  padding: 0;
  width: 100%;

  ${p =>
    !p.noGutter &&
    css`
      padding: ${p => p.theme.gutter};
    `};

  &::after {
    content: '\00a0'; /* &nbsp; */
    display: block;
    margin-top: 0;
    height: 0px;
    visibility: hidden;
  }
`

export default Page
