import gql from 'graphql-tag'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { photo, slug } from '../routes/links'
import { IPhotosOnQueryArguments, IQuery } from '../types/schema-types'
import ErrorMessage from './ErrorMessage'
import Grid from './Grid'
import GridFigure from './GridFigure'
import Section from './Section'

interface PhotoGridProps {
  categoryId?: string | null
}

interface Thumb {
  scaledWidth: number
  width: number
  height: number
}

class PhotoGrid extends Component<PhotoGridProps> {
  render() {
    const { categoryId } = this.props
    const variables = categoryId != null ? { categoryId } : undefined
    // If we don't have a categoryId, order by recent.
    const recent = !categoryId ? true : undefined

    return (
      <QueryPhotos
        query={QUERY_PHOTOS}
        variables={variables}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) return <div>Loading...</div>
          if (error) return <ErrorMessage error={error} />
          if (!data || !data.photos) return <div>No data</div>

          const { photos } = data

          if (!photos.length) {
            return <Section>No photos.</Section>
          }

          return (
            <Section>
              <Grid
                rowHeight={200}
                gutter={24}
                aspectRatios={photos.map(
                  ({ thumbnail: { width, height } }) => width / height
                )}
                render={({ top, left, width, height }, index) => {
                  const v = photos[index]
                  return (
                    <GridFigure
                      key={v.thumbnail.url}
                      top={top}
                      left={left}
                      width={width}
                      height={height}
                    >
                      <Link key={v.id} to={photo(slug(v), { recent })}>
                        <img src={v.thumbnail.url} alt={v.title} width="100%" height="100%" />
                      </Link>
                    </GridFigure>
                  )
                }}
              />
            </Section>
          )
        }}
      </QueryPhotos>
    )
  }
}

const QUERY_PHOTOS = gql`
  query Photos($categoryId: ID) {
    photos(categoryId: $categoryId) {
      id
      url
      filename
      title
      slug
      thumbnail {
        url
        width
        height
      }
    }
  }
`

class QueryPhotos extends Query<
  Pick<IQuery, 'photos'>,
  IPhotosOnQueryArguments
> {}

export default PhotoGrid
