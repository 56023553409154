import { ID, IPhoto } from '../types/schema-types'

export const home = () => '/'
export const login = () => '/login'
export const logout = () => '/logout'
export const links = () => '/links'

// export const categories = () => '/categories'
// export const categoryAdd = () => '/category/add'
export const category = (idSlug?: ID) =>
  replace('/category/:idSlug', { idSlug })

// export const photos = () => '/photos'
// export const photoAdd = () => '/photo/add'
export const photo = (idSlug?: ID, opts?: { recent?: boolean }) =>
  replace('/photo/:idSlug', { idSlug }) + serialize(opts)

// export const adminPhotos = () => '/admin/photos'
export const adminPhotoAdd = () => '/admin/photo/add'
export const adminPhotoEdit = (idSlug?: ID) =>
  replace('/admin/photo/:idSlug/edit', { idSlug })

export const adminCategories = () => '/admin/categories'
export const adminCategoryAdd = () => '/admin/category/add'
export const adminCategory = (idSlug?: ID) =>
  replace('/admin/category/:idSlug', { idSlug })

export const slug = (v: { id: ID; slug?: string }) => {
  if (!v.slug) {
    return v.id
  }
  return `${v.id}-${v.slug}`
}

export const replace = (path: string, values: Record<string, any>): string => {
  if (values) {
    Object.keys(values).map(key => {
      const value = values[key]
      if (value != null) {
        path = path.replace(':' + key, value)
      }
    })
  }
  return path
}

export const serialize = (
  obj?: Record<string, any>,
  prefix: string = '?'
): string => {
  if (obj == null) {
    return ''
  }
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      // keys that start with $ will not be encoded
      const encode = p.startsWith('$')
      const val = obj[p]
      if (val != null) {
        if (encode) {
          str.push(`${p.substring(1)}=${val}`)
        } else {
          str.push(`${encodeURIComponent(p)}=${encodeURIComponent(val)}`)
        }
      }
    }
  }
  return str.length > 0 ? prefix + str.join('&') : ''
}
