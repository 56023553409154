import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { photo } from '../routes/links'
import styled, { css } from '../theme/styled-components'
import { ID } from '../types/schema-types'

interface PhotoViewerProps {
  title: string
  url: string
  nextPhotoId?: ID | null
  previousPhotoId?: ID | null
  recent?: boolean
}

const PhotoViewer: React.SFC<PhotoViewerProps> = ({
  url,
  title,
  recent,
  nextPhotoId,
  previousPhotoId,
}) => {
  const photosRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const resizePhoto = () => {
      const photo = photosRef.current
      if (!photo) {
        return
      }
      // 80px should just about fit the photo title
      const bottomPadding = 80

      const rect = photo.getBoundingClientRect()

      const docEl = document.documentElement
      const windowHeight = window.innerHeight


      const height = Math.max(
        windowHeight - (rect.top + docEl.scrollTop) - bottomPadding,
        400
      )

      photo.style.maxHeight = `${height}px`
    }

    resizePhoto()

    window.addEventListener('resize', resizePhoto)

    return () => {
      window.removeEventListener('resize', resizePhoto)
    }
  }, [])

  return (
    <Container ref={photosRef}>
      <Frame>
        <Photo src={url} alt={title} />
      </Frame>

      {nextPhotoId && (
        <Arrow $direction="left" to={photo(nextPhotoId, { recent })}>
          <svg viewBox="0 0 32 32" fill="currentColor">
            <path d="M14.19 16.005l7.869 7.868-2.129 2.129-9.996-9.997L19.937 6.002l2.127 2.129z" />
          </svg>
        </Arrow>
      )}
      {previousPhotoId && (
        <Arrow $direction="right" to={photo(previousPhotoId, { recent })}>
          <svg viewBox="0 0 32 32" fill="currentColor">
            <path d="M18.629 15.997l-7.083-7.081L13.462 7l8.997 8.997L13.457 25l-1.916-1.916z" />
          </svg>
        </Arrow>
      )}
    </Container>
  )
}

interface ArrowProps {
  $direction: 'left' | 'right'
}

const Arrow = styled(Link) <ArrowProps>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 30%;
  opacity: .35;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  z-index: 2;
  color: #fff;

  svg {
    width: ${p => p.theme.gutter};
  }

  ${p =>
    p.$direction === 'left'
      ? css`
          left: 0;
          padding-left: ${p => p.theme.gutter};
          justify-content: flex-start;
        `
      : css`
          right: 0;
          padding-right: ${p => p.theme.gutter};
          justify-content: flex-end;
        `}

  &:hover,
  &:active {
    color: #fff;
    opacity: 1;
  }
`

const Photo = styled.img`
  height: 100%;
  max-width: 100%;
  max-height: 1050px;
  /* border-radius: 8px; */
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
`

const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  margin-left: ${p => p.theme.gutter};
  margin-right: ${p => p.theme.gutter};
  z-index: 1;
  /* min-height: 400px; */
`

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: ${p => p.theme.gutter};
  padding-right: ${p => p.theme.gutter};

  /*
  &:hover,
  &:active {
    ${Arrow} {
      opacity: 1;
    }
  }
  */
`

export default PhotoViewer
